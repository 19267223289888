import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { Link, withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import { ValidateCI } from '../utils/validateCi'
import MaskedInput from "react-text-mask";
import NumberFormat from 'react-number-format';


import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik'



const Solicitud = (props) => {


  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = props.data.prismic.allFooters.edges[0].node;
  const $layoutData = { $metadata, $footer };

  //Setters
  const [loading, setLoading] = React.useState(false);
  const [showToast, setToast] = React.useState(false);
  const [blurredDate, setBlurredDate] = React.useState(false);
  const [mesValue, setMes] = React.useState();
  const [diaValue, setDia] = React.useState();
  const [anoValue, setAno] = React.useState();
  const [activeStep, setActiveStep] = React.useState(1);


  //First step
  const [lead_id, setLeadID] = React.useState();
  const [request_30, setRequest30] = React.useState(false);
  const [offer_crediton, setOfferCrediton] = React.useState({ approved: false, mount: 0 });
  const [offer_plus, setOfferPlus] = React.useState({ approved: false, mount: 0 });


  //Second step
  const [selectedCompany, setCompany] = React.useState();
  const [selectedMethod, setMethod] = React.useState();

  const selectCompany = (company) => {

    /*
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)

    */

    setCompany(company);
    //setActiveStep(3);

  }

  const [whatsapp, setWhatsapp] = React.useState();


  //Form
  const campoError = 'El campo es requerido'
  var noValidate = false;
  var errors = {};

  //Date validation
  const getDia = (evt) => {

    setDia(evt.target.value)
    setBlurredDate(true);

  }
  const getAno = (evt) => {

    setAno(evt.target.value);
    setBlurredDate(true);

  }
  const getMes = (evt) => {

    setMes(evt.target.value)
    setBlurredDate(true);

  }


  const maxLengthCheck = (object) => {

    if (object.target.value.length == object.target.maxLength) {

      if (object.target.nextSibling) {
        object.target.nextSibling.focus()
      }

    }

    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }





  }

  //CI

  const CIMask = [
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/
  ];


  //Tel
  
  const TelMask = [
    /[0]/,
    /[9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/
  ];




  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-solicitud'} >


        <SEO metadata={$metadata} title={'Buscar préstamo'}></SEO>


        <>

          <Helmet>

            <link href={withPrefix('../../assets/css/fixed/screen.css?v=4')} rel="stylesheet" type="text/css" />
            <link href={withPrefix('../../assets/css/sitio/solicitud.css?v=4')} rel="stylesheet" type="text/css" />

          </Helmet>



          {/* POP-UP BODY*/}
          <div className="c-solicitud">
            <div className="c-hero">
              <div className="c-hero__wrapper">
                <span className="c-hero__logo" />
                <div className="c-footer-solicitud c-footerdesktop">
                  <div className="c-footer__text">

                    <Link to={`/condiciones`} className="c-footer__link c-footer-solicitud__link" >Términos y condiciones</Link> | {' '}
                    <Link to={`/politica-de-privacidad`} className="c-footer__link c-footer-solicitud__link" >Política de privacidad</Link>

                  </div>
                </div>{/* .c-footer */}
              </div>
            </div>{/* .c-hero */}{/*
                */}<div className="c-viewport o-wrapper">{/*
                    */}<div className="c-viewport__holder o-section">

                {showToast && !loading &&
                  <div className="c-toast is-active" onClick={() => { setToast(false) }}>
                    <div className="c-toast_holder">
                      <span className="c-toast__icon" />
                      <button className="c-toast__close" onClick={() => { setToast(false) }} />
                      <span className="c-toast__alert"><strong>Error:</strong></span>
                      <span className="c-toast__text">Ha ocurrido un error al enviar el formulario, intente nuevamente.</span>
                    </div>
                  </div>
                }

                {loading &&
                  <div className="c-viewport__step c-loading">
                    <span className="c-loading-animation c-loading-animation--large" />
                    <span className="c-loading-text">Cargando...</span>
                  </div>
                }

                {activeStep == 1 && !loading &&
                  <div className="c-viewport__step c-viewport__step--small">
                    <div className="c-viewport__step-holder">
                      <div className="c-solicitud__text--align">
                        <h2 className="c-solicitud__title c-solicitud__text--align-left">¡Encontrá la mejor<br />oferta para ti!</h2>
                        <p className="c-solicitud__text c-solicitud__text--align-left">Completá tus datos personales</p>
                      </div>


                      <Formik
                        initialValues={{
                          identity_doc: '',
                          cellphone: '',
                          clearing: '',
                          birthday: '-',
                          requested_amount: '',
                          job_status: 'null',
                          gclid: 'xxxxxxxxxx',
                          conditions: true,
                        }}

                        onSubmit={
                          (values, actions) => {

                            setLoading(true);

                            //Clean values
                            delete values.conditions;
                            values.identity_doc = values.identity_doc.replace(/\D/g, '');
                            values.cellphone = values.cellphone.replaceAll(' ', '');
                            values.cellphone = values.cellphone.trim();

                            localStorage.getItem('gclid') != null ? values.gclid = localStorage.getItem('gclid') : values.gclid = '';

                            //Fetch to API
                            (async () => {

                              const rawResponse = await fetch(`https://becrof.volmet.com.uy/api/v1/leads/new/offer_a_loan`, {
                                method: "POST",
                                headers: {
                                  'Accept': "application/json",
                                  'Content-Type': "application/json",
                                  'Authorization': 'Token token=de2f5af31f4e3b2b7746'
                                }, withCredentials: true,
                                body: JSON.stringify({ ...values })
                              })

                              //Get API response
                              const response = await rawResponse.json();

                              if (response.status == 200) {

                                //Patch response data
                                setLeadID(response.lead_id);
                                setOfferCrediton({ approved: response.offer_a_loan_crediton, mount: response.preapproved_crediton });
                                setOfferPlus({ approved: response.offer_a_loan_plus, mount: response.preapproved_plus });
                                setRequest30(response.rejected_less_30 == 'Si' ? true : false);

                                setActiveStep(2);
                                setLoading(false);


                                window.dataLayer.push({
                                  event: 'act-solicitar-ofertas',
                                })
                              

                                //window.dataLayer.push({'event': 'bloque-gtm'})
                                /*
                                var tracker = window.ga.getAll()[0];
                                tracker.send('event', 'solicitar_oferta', 'Click', 'Solicitud');
                                */

                              } else {

                                setLoading(false);
                                setToast(true);

                                setTimeout(() => {
                                  setToast(false);
                                }, 5000);

                              }

                            })();

                          }
                        }
                        validate={values => {

                          noValidate = true;

                          errors = {};

                          //Required
                          Object.keys(values).
                            forEach((id) => {
                              if (!values[id] || values[id] == "null") {
                                errors[id] = campoError
                              }
                            })

                          //CI
                          if (values.identity_doc && (!ValidateCI(values.identity_doc) || values.identity_doc.length < 7)) {
                            errors.identity_doc = "La cédula no es válida"
                          }


                          //Birthday
                          if (!mesValue || !anoValue || !diaValue) {
                            errors.birthday = "La fecha no es válida"
                          }

                          if (mesValue && anoValue && diaValue) {

                            if (mesValue <= 12 && mesValue > 0 && diaValue <= 31 && diaValue > 0 && anoValue > 0 && anoValue.length == 4) {
                              values.birthday = anoValue + "-" + mesValue + "-" + diaValue
                            } else {
                              errors.birthday = "La fecha no es válida"
                            }

                          }

                          //Cellphone
                          if (values.cellphone && (values.cellphone.length < 9 || !/^\d+$/.test(values.cellphone.replaceAll(' ', '')))) {
                            errors.cellphone = "El celular no es válido"
                          }

                          //Amount
                          if (values.requested_amount && values.requested_amount < 4000) {
                            errors.requested_amount = "El valor debe ser mayor a $4.000"
                          }
                          if (values.requested_amount && values.requested_amount > 300000) {
                            errors.requested_amount = "El valor debe ser menor a $300.000"
                          }

                          //Round amount
                          if (values.requested_amount && values.requested_amount % 100 !== 0) {
                            values.requested_amount = Math.ceil(values.requested_amount / 100) * 100;
                          }

                          return errors;





                        }}
                      >
                        {({ validateForm, }) => (

                          <Form key="solicitud-form" name="solicitud-form" method="POST" className="c-form">

                            <div className="c-form__group">

                              <ErrorMessage name="identity_doc" children={() => { return (<label htmlFor="identity_doc" className="c-form__error">{errors.identity_doc}</label>) }} />

                              <label className="c-form__label">

                                <span className="c-form__label-text">Cédula de Identidad</span>


                                <Field
                                  name="identity_doc"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={CIMask}
                                      placeholder="0.000.000-0"
                                      type="text"
                                      className="c-form__input"
                                    />
                                  )}
                                />




                              </label>


                            </div>

                            <div className="c-form__group">

                              <ErrorMessage name="birthday" children={() => { return (<label htmlFor="birthday" className="c-form__error">{errors.birthday}</label>) }} />
                              {errors.birthday && blurredDate && <label htmlFor="birthday" className="c-form__error">{errors.birthday}</label>}

                              <div className="c-form-birthday">


                                <label htmlFor="day" className="c-form__label-text">Fecha de Nacimiento</label>
                                <input id="day" min="1" max="31" type="number" className="c-form__input c-form__input-birthday" placeholder="DD" onFocus={getDia} onChange={getDia} maxLength="2" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />{/*
                                            */}<input min="1" max="12" type="number" className="c-form__input c-form__input-birthday" placeholder="MM" onFocus={getMes} onChange={getMes} maxLength="2" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />{/*
                                            */}<input min="1900" type="number" className="c-form__input c-form__input-birthday" placeholder="AAAA" onFocus={getAno} onChange={getAno} maxLength="4" onInput={maxLengthCheck} onBlur={() => { validateForm() }} />

                              </div>{/* .c-form-birthday */}
                            </div>


                            <div className="c-form__group">

                              <ErrorMessage name="cellphone" children={() => { return (<label htmlFor="cellphone" className="c-form__error">{errors.cellphone}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Celular</span>

                                <Field
                                  name="cellphone"
                                  render={({ field }) => (
                                    <MaskedInput
                                      {...field}
                                      mask={TelMask}
                                      placeholder="099 999 999"
                                      type="text"
                                      className="c-form__input"
                                    />
                                  )}
                                />

                              </label>


                            </div>
                            <div className="c-form__group">

                              <ErrorMessage name="clearing" children={() => { return (<label htmlFor="clearing" className="c-form__error">{errors.clearing}</label>) }} />

                              <div className="c-form__clearing c-form__label">
                                <span className="c-form__label-text">¿Estás en el clearing?</span>
                                <label className="c-form__label-birthday">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing" value="si" />

                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">Si</span>

                                </label>
                                <label className="c-form__label-birthday">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing" value="no" />
                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">No</span>
                                </label>
                                <label className="c-form__label-birthday">

                                  <Field name="clearing" type="radio" className="c-form__input-clearing" value="no lo se" />
                                  <span className="c-radio__icon" />
                                  <span className="c-radio__text">No lo sé</span>
                                </label>
                              </div>{/* .c-form__clearing */}


                            </div>


                            <div className="c-form__group">

                              <ErrorMessage name="requested_amount" children={() => { return (<label htmlFor="requested_amount" className="c-form__error">{errors.requested_amount}</label>) }} />

                              <label className="c-form__label">
                                <span className="c-form__label-text">Monto a Solicitar</span>
                                <Field name="requested_amount" type="number" className="c-form__input" min="4000" max="300000" step="100" placeholder="50000" />
                              </label>

                            </div>


                            <div className="c-form__group">



                              <label htmlFor="job_status" className="c-form__label-text">Situación Laboral</label>

                              <ErrorMessage name="job_status" children={() => { return (<label htmlFor="job_status" className="c-form__error">{errors.job_status}</label>) }} />

                              <div className="c-form__select">

                                <Field name="job_status" as="select" className={`c-form__input ${!noValidate || errors.job_status ? 'is-placeholder' : ''}`}>
                                  <option disabled value="null">Selecciona una situación laboral...</option>
                                  <option value="independiente">Independiente</option>
                                  <option value="empleado">Empleado</option>
                                  <option value="jubilado">Jubilado/Pensionista</option>
                                </Field>


                              </div>

                            </div>


                            <label className="c-form__label c-form__label--relative">

                              <ErrorMessage name="conditions" children={() => { return (<label htmlFor="conditions" className="c-form__error">{errors.conditions}</label>) }} />

                              <Field name="conditions" type="checkbox" className="c-form__input-conditions" />

                              <span className="c-checkbox__icon" />
                              <span className="c-form__label-text c-form__label-text--inline">He leído y acepto los <strong><a href="/condiciones" target="_blank" className="c-form__label-link">Términos y condiciones</a></strong> y gestionar la atención al cliente vía <strong><a className="c-form__label-link" target="_blank" href="https://wa.me/598099270679">Whatsapp</a></strong> </span>
                            </label>

                            <button type="submit" className={`c-btn c-form__btn ${loading ? 'is-loading' : ''} `}> {loading && <span className="c-loading-animation" />} Continuar</button>
                          </Form>

                        )}

                      </Formik>
                    </div>
                  </div>
                }

                {activeStep == 2 && !loading &&
                  <Formik
                    initialValues={{
                      lead_id: '',
                      company: '',
                      contact_method: ''
                    }}
                    onSubmit={
                      (values, actions) => {

                        setLoading(true);

                        //Update values
                        values.lead_id = lead_id;
                        values.company = selectedCompany;
                        values.contact_method = 'whatsapp';

                        //Fetch to API
                        (async () => {

                          const rawResponse = await fetch(`https://becrof.volmet.com.uy/api/v1/leads/contact/offer_a_loan`, {
                            method: "POST",
                            headers: {
                              'Accept': "application/json",
                              'Content-Type': "application/json",
                              'Authorization': 'Token token=de2f5af31f4e3b2b7746'
                            }, withCredentials: true,
                            body: JSON.stringify({ ...values })
                          })

                          //Get API response
                          const response = await rawResponse.json();

                          if (response.status == 200) {

                            setWhatsapp(response.link ? response.link : '');

                            setActiveStep(4);
                            setLoading(false);

                            
                            window.dataLayer.push({
                              event: 'act-confirmar-oferta',
                            })

                            /*
                            var tracker = window.ga.getAll()[0];
                            tracker.send('event', 'confirmar_oferta', 'Click', 'Solicitud');
                            */


                          } else {

                            setToast(true);
                            setLoading(false);

                            setTimeout(() => {
                              setToast(false);
                            }, 5000);

                          }
                        })();

                      }
                    }
                  >
                    {({ submitForm }) => (

                      <Form key="contacto-form" name="contacto-form" method="POST" >
                        <div className="c-viewport__step">


                          {/* SIN OFERTAS */}

                          {/* Sin solicitud previa */}
                          {!offer_crediton.approved && !offer_plus.approved && !request_30 &&
                            <>
                              <p className="c-solicitud__title"><span className="c-viewport__icon c-viewport__icon--sad" />Lo sentimos</p>
                              <p className="c-solicitud__text">En este momento no encontramos una oferta para ti.</p>
                              <p className="c-solicitud__text c-solicitud__text--large">Te sugerimos volver a intentarlo dentro de 30 días. Recordá que cuanto más completo sea tu perfil, mayores serán las chances de conseguir una mejor oferta de crédito.</p>
                            </>

                          }

                          {/* Con solicitud previa */}
                          {request_30 &&
                            <>
                              <p className="c-solicitud__title"><span className="c-viewport__icon c-viewport__icon--sad" />Lo sentimos</p>
                              <p className="c-solicitud__text">Debes esperar 30 días a tu anterior solicitud.</p>
                              <p className="c-solicitud__text c-solicitud__text--large">Recordá que cuanto más completo sea tu perfil, mayores serán las chances de conseguir una mejor oferta de crédito.</p>
                            </>

                          }


                          {/* CON OFERTAS Sin solicitud previa */}

                          {!request_30 && (offer_crediton.approved || offer_plus.approved) &&
                            <>
                              <p className="c-solicitud__title"><span className="c-viewport__icon c-viewport__icon--heart" /> {offer_crediton.approved && offer_plus.approved && '¡Tenemos más de un crédito pre-aprobado para ti!'} {(!offer_crediton.approved && offer_plus.approved) || (offer_crediton.approved && !offer_plus.approved) && '¡Tenemos un crédito pre-aprobado para ti!'}</p>
                              {offer_crediton.approved && offer_plus.approved && <p className="c-step__sub-text">Hacé click en la opción que más se adapte a ti</p>}
                              {(!offer_crediton.approved && offer_plus.approved) || (offer_crediton.approved && !offer_plus.approved) && <p className="c-step__sub-text">Hacé click en la opción para seleccionarla</p>}


                              <div className="c-solicitud__ofertas-holder">


                                {offer_crediton.approved &&

                                  <button className="c-solicitud__item" onClick={() => {
                                    selectCompany('crediton');
                                    submitForm();
                                  }}>
                                    <span className="c-solicitud__oferta">
                                      <span className="c-solicitud__oferta-image">
                                        <span className="c-solicitud__oferta-logo c-solicitud__oferta-logo--crediton" />
                                      </span>


                                      <span className="c-solicitud__oferta-info">
                                        <span className="c-solicitud__oferta-total">
                                          <span className="c-solicitud__oferta-monthlyfee">hasta</span>
                                          <strong><NumberFormat value={offer_crediton.mount} displayType={'text'} thousandSeparator={'.'} prefix={'$'} decimalSeparator={','} /></strong>
                                        </span>
                                        <button className="c-btn c-solicitud__oferta-btn">Quiero</button>
                                      </span>


                                    </span>

                                  </button>}{/*
            */}{offer_plus.approved &&

                                  <button className="c-solicitud__item" onClick={() => {
                                    selectCompany('creditonplus');
                                    submitForm();
                                  }}>
                                    <span className="c-solicitud__oferta">
                                      <span className="c-solicitud__oferta-image">
                                        <span className="c-solicitud__oferta-logo c-solicitud__oferta-logo--creditonplus" />
                                      </span>


                                      <span className="c-solicitud__oferta-info">
                                        <span className="c-solicitud__oferta-total">
                                          <span className="c-solicitud__oferta-monthlyfee">hasta</span>
                                          <strong><NumberFormat value={offer_plus.mount} displayType={'text'} thousandSeparator={'.'} prefix={'$'} decimalSeparator={','} /></strong>
                                        </span>
                                        <button className="c-btn c-solicitud__oferta-btn">Quiero</button>
                                      </span>


                                    </span>
                                  </button>
                                }
                              </div>

                              <p className="c-step__disclaimer">* Ofertas máximas sujetas a aprobación crediticia y a verificación de ingresos. Podrás indicar el monto y las cuotas que deseás más adelante.</p>

                            </>
                          }



                        </div>

                      </Form>
                    )}

                  </Formik>
                }


                {activeStep == 3 && !loading &&

                  <Formik
                    initialValues={{
                      lead_id: '',
                      company: '',
                      contact_method: ''
                    }}
                    onSubmit={
                      (values, actions) => {

                        setLoading(true);

                        //Update values
                        values.lead_id = lead_id;
                        values.company = selectedCompany;
                        values.contact_method = selectedMethod;

                        //Fetch to API
                        (async () => {

                          const rawResponse = await fetch(`https://becrof.volmet.com.uy/api/v1/leads/contact/offer_a_loan`, {
                            method: "POST",
                            headers: {
                              'Accept': "application/json",
                              'Content-Type': "application/json",
                              'Authorization': 'Token token=de2f5af31f4e3b2b7746'
                            }, withCredentials: true,
                            body: JSON.stringify({ ...values })
                          })

                          //Get API response
                          const response = await rawResponse.json();

                          if (response.status == 200) {

                            setWhatsapp(response.link ? response.link : '');

                            setActiveStep(4);
                            setLoading(false);


                          } else {

                            setToast(true);
                            setLoading(false);

                            setTimeout(() => {
                              setToast(false);
                            }, 5000);

                          }
                        })();

                      }
                    }
                  >
                    {({ submitForm }) => (

                      <Form key="contacto-form" name="contacto-form" method="POST" >


                        <div className="c-viewport__step">
                          <p className="c-solicitud__text c-solicitud__text--big">Has seleccionado la siguiente oferta:</p>
                          <div className="c-solicitud__ofertas-holder">
                            <div className="c-solicitud__oferta c-solicitud__oferta-selected">
                              <div className="c-solicitud__oferta-image">
                                <span className={`c-solicitud__oferta-logo ${selectedCompany == 'crediton' ? 'c-solicitud__oferta-logo--crediton' : 'c-solicitud__oferta-logo--creditonplus'} `} />
                              </div>
                              <div className="c-solicitud__oferta-info">

                                <p className="c-solicitud__oferta-total">
                                  <span className="c-solicitud__oferta-monthlyfee">Tu préstamo de hasta</span>
                                  <strong>$ {selectedCompany == 'crediton' ? offer_crediton.mount : offer_plus.mount}</strong>
                                </p>



                              </div>
                            </div>
                            <p className="c-solicitud__title c-solicitud__title--small">Finaliza tu solicitud:</p>
                            <div className="c-solicitud__btns">
                              <button onClick={() => {
                                setMethod('whatsapp'); submitForm();
                              }} className="c-btn c-solicitud__btn c-solicitud--wsp"><span className="c-solicitud__btn-icon c-solicitud__wsp-icon" />A través de Whatsapp</button>
                              <br />
                              <button
                                onClick={() => {
                                  setMethod('phone'); submitForm()
                                }} className="c-btn c-solicitud__btn c-solicitud--callme"><span className="c-solicitud__btn-icon c-solicitud__callme-icon" />Quiero que me llamen</button>
                            </div>
                          </div>

                          <p className="c-step__disclaimer">* Sujeto a aprobación crediticia.</p>


                        </div>

                      </Form>
                    )}

                  </Formik>

                }

                {activeStep == 4 && !loading &&
                  <div className="c-viewport__step u-text-center">

                    <p className="c-solicitud__title">
                      <span className="c-viewport__icon c-viewport__icon--heart" />
                      ¡Gracias por elegir Crediofertas!</p>
                    <p className="c-solicitud__text">Nos estaremos comunicando contigo en las próximas horas.</p>

                    {whatsapp &&
                      <div className="c-solicitud__btns">
                        <a href={whatsapp} className="c-btn c-solicitud__btn c-solicitud--wsp"><span className="c-solicitud__btn-icon c-solicitud__wsp-icon" />Enviar Whatsapp</a>
                      </div>
                    }

                    <Link to={`/`} className="c-btn c-btn--filled">Volver al inicio</Link>

                  </div>
                }



              </div>
            </div>{/* .c-viewport__holder */}
          </div>



        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query SolicitudQuery {
  prismic {
    allMetadatas  {
      edges {
        node {
          ...MetadataFragment
        }
      }
    },
    allFooters {
      edges {
        node {
          ...FooterFragment
        }
      }
    } 
  }
  
}
`



export default Solicitud;

